<template>
  <div style="min-height: 100vh;">
    <div
      @click="pay"
      style="font-size:30px;text-align:center;line-height:2;cursor:pointer"
    >
      支付
    </div>
    <qr-code text="http://www.baidu.com"></qr-code>
    <div
      @click="getWxLogin"
      style="font-size:30px;text-align:center;line-height:2;cursor:pointer"
    >
      微信登录
    </div>
    <div class="test-container">
      <p class="test">
        你好卡高级哦alwgjaowgjja
      </p>
    </div>
  </div>
</template>

<script>
import wxtool from "@/assets/utils/wx-tool.js";
export default {
  mounted() {
    wxtool.init().then(wx => {
      wx.updateAppMessageShareData({
        title: "11",
        desc: "222",
        link: document.URL,
        imgUrl: "",
        success() {
          console.log("11");
        }
      });
    });
  },
  data() {
    return {
      snsapi: "https://open.weixin.qq.com/connect/oauth2/authorize?"
    };
  },
  computed: {
    codeUrl() {
      const url = this.snsapi;
      const callback = encodeURIComponent(this.officialAccount.callback);
      return `${url}appid=${this.officialAccount.appid}&redirect_uri=${callback}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
    }
  },
  methods: {
    pay() {
      this.$request.get("/api/test/alipay").then(res => {
        console.log(res);
        document.write(res.data.form);
      });
    },
    getWxLogin() {
      window.location.href = this.codeUrl;
    }
  }
};
</script>

<style lang="scss" scoped>
.test-container {
  width: 100%;
  display: inline-block;
  position: relative;
  overflow: hidden;
}
.test {
  font-size: 100px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  white-space: nowrap;
  mix-blend-mode: exclusion;
  z-index: 111;
}
</style>
